import React, { useState } from "react";
import Layout from "../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import VideoPopup from "../../VideoPopup";
import Seo from "../../seo";
import "./style.scss";
import { galImageData, galVideoData } from "./data";

export default function SchoolGallery() {
  const [activeVideo, setActiveVideo] = useState({});
  const [showPopup, setShowPopup] = useState(false);

  const settings = {
    // dots: true,
    infinite: true,
    arrows: true,
    // fade: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function onClickPopupClose() {
    setActiveVideo({});
    setShowPopup(false);
  }

  function VideoSlider({ setActiveVideo }) {
    return (
      <div className="gallery__slider">
        <Slider {...settings}>
          {galVideoData.map((item, i) => (
            <div className="gallery__videos__slide">
              <div className="gallery__videos__card">
                <img alt="" src={item.imgUrlH}></img>
                <button
                  onClick={() => {
                    setActiveVideo(item);
                    setShowPopup(true);
                  }}
                >
                  <i className="fa fa-play"></i>
                </button>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      <Seo
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      />
      <VideoPopup
        onClose={onClickPopupClose}
        url={activeVideo.tvideo}
        show={showPopup}
      />
      <div className="gallery">
        <h1 className="heading">School Gallery</h1>
        <section className="gallery__videosection">
          <VideoSlider setActiveVideo={setActiveVideo} />
        </section>

        <section className="gallery__photosection">
          <div className="gallery__photos">
            {galImageData.map((item) => (
              <div className="gallery__photos__card">
                <img alt="" src={item.img}></img>
              </div>
            ))}
          </div>
        </section>
      </div>
    </Layout>
  );
}
