import React, { useEffect } from "react";
import ReactPlayer from "react-player";
import "./style.scss";
export default function VideoPopup({
  url = "https://www.youtube.com/embed/YGRrVFiBhc0",
  show = false,
  onClose = () => {},
}) {
  useEffect(() => {
    if (show) {
      document.querySelector("body").style.overflow = "hidden";
      document.querySelector("body").style.maxHeight = "100vh";
    } else {
      document.querySelector("body").style.overflow = "unset";
      document.querySelector("body").style.maxHeight = "none";
    }
  }, [show]);
  return (
    <div className={`video-popup ${show && "video-popup--active"}`}>
      <button onClick={onClose}>
        <i className="fa fa-times"></i>
      </button>
      <ReactPlayer url={url} controls />
    </div>
  );
}
